import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  height: 100%;
  overflow: auto;
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Row = styled.tr`
  background: ${colors.white};
  & td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;
const JobRow = styled.tr`
  background: ${colors.white};
  & td {
    border-top: 1px solid #dee2e6 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;


export default class CashReceive extends React.Component {
  componentDidMount() {
    const {onLoad} = this.props;
    onLoad();
  }
  onToggle(index, e) {
    this.props.onToggle(index);
  }
  onReceive(id) {
    const {onReceive} = this.props;
    onReceive(id)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while submitting cash report.');
        } else {
          toast.success('Cash report successfully submitted.');
        }
      })
      .catch(e => {
        toast.error('Error encountered while submitting cash report.');
      });
  }
  render() {
    console.log('cashreceive:render');
    const {notFound} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load cash receiptes.</div>
        </ErrorContainer>
      );
    }
    const {
      cashReports,
    } = this.props;
    return (
      <Container className='flex-grow-1 p-3'>
        <h5 className='mb-0'>Cash Receive</h5>
        <Card className='p-3 mt-3'>
          <table className='table mb-0 mt-3'>
            <thead>
              <tr>
                <th className='border-0 py-0' width={50} />
                <th className='border-0 py-0'>Reporter</th>
                <th className='border-0 py-0'>Report Date</th>
                <th className='border-0 py-0'>Total</th>
                <th className='border-0 py-0'>Receiver</th>
                <th className='border-0 py-0'>Receive Date</th>
              </tr>
            </thead>
            <tbody>
              {cashReports.length === 0 && (
                <div className='text-center text-secondary'>No cash reports found.</div>
              )}
              {cashReports.map((t, i) => (
                <React.Fragment key={i}>
                  <tr>
                    <td className='p-1 border-0' />
                  </tr>
                  <Row>
                    <td>
                      {t.open !== true && (
                        <Icons.ChevronDown color={colors.primary} onClick={this.onToggle.bind(this, i)} open={t.open} />
                      )}
                      {t.open === true && (
                        <Icons.ChevronUp color={colors.primary} onClick={this.onToggle.bind(this, i)} open={t.open} />
                      )}
                    </td>
                    <td>{t.reporter}</td>
                    <td>{t.reportDateStr}</td>
                    <td>{t.totalStr}</td>
                    <td>{t.receiver}</td>
                    <td>{t.receiveDateStr}</td>
                    <td align='right'>
                      {t.receiver === null && (
                        <button
                          className='btn btn-primary'
                          onClick={this.onReceive.bind(this, t.id)}
                        >Receive</button>
                      )}
                    </td>
                  </Row>
                  {t.open === true && (
                    <Row>
                      <td />
                      <td colSpan={5}>
                        <table className='table mb-0 mt-1'>
                          <thead>
                            <tr>
                              <th className='border-top-0 py-0 font-weight-medium'>Job #</th>
                              <th className='border-top-0 py-0'>Rego</th>
                              <th className='border-top-0 py-0'>Make/model</th>
                              <th className='border-top-0 py-0'>Pay date</th>
                              <th className='border-top-0 py-0'>Payee</th>
                              <th className='border-top-0 py-0'>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {t.jobs.map((r, j) => (
                              <JobRow key={j}>
                                <td className='py-0'>
                                  <Link to={`/job/${r.jobId}`}>
                                    <strong className='text-body'>{r.jobNumber}</strong>
                                  </Link>
                                </td>
                                <td className='py-0'>{r.rego}</td>
                                <td className='py-0 text-uppercase'>{r.makeModel}</td>
                                <td className='py-0'>{r.payDateStr}</td>
                                <td className='py-0'>{r.payee}</td>
                                <td className='py-0'>{r.amountStr}</td>
                              </JobRow>
                            ))}
                            <tr>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td />
                    </Row>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Card>
      </Container>
    );
  }
};

