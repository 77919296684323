import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import CashReceive from './component.jsx';


const load = API => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/cash-receive`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-CASH-RECEIVE-SET-NOT-FOUND'
          });
          return null;
        }
        const {rows} = resp;
        dispatch({
          type: 'ADMIN-CASH-RECEIVE-SET',
          rows,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const toggle = (index) => ({
  type: 'ADMIN-CASH-RECEIVE-TOGGLE',
  index,
});

const receive = (API, id) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/cash-receive`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          id,
        })
      }
    )
      .then(resp => {
        if (!resp || resp.error === true) {
          return false;
        }
        const {rows} = resp;
        dispatch({
          type: 'ADMIN-CASH-RECEIVE-SET',
          rows,
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {
    adminCashReceive,
  } = state;
  return adminCashReceive;
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: () => dispatch(load(API)),
  onToggle: (index) => dispatch(toggle(index)),
  onReceive: id => dispatch(receive(API, id)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CashReceive)
);

