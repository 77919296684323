import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import CashReport from './component.jsx';


const load = API => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/cash-report`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-CASH-REPORT-SET-NOT-FOUND'
          });
          return null;
        }
        const {cashes} = resp;
        dispatch({
          type: 'ADMIN-CASH-REPORT-SET',
          cashes,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const toggle = (index, selected) => ({
  type: 'ADMIN-CASH-REPORT-TOGGLE',
  index,
  selected
});

const report = (API, cashes) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/cash-report`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          cashes,
        })
      }
    )
      .then(resp => {
        if (!resp || resp.error === true) {
          return false;
        }
        const {cashes} = resp;
        dispatch({
          type: 'ADMIN-CASH-REPORT-SET',
          cashes,
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {
    adminCashReport,
  } = state;
  return adminCashReport;
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: () => dispatch(load(API)),
  onToggle: (index, selected) => dispatch(toggle(index, selected)),
  onReport: cashes => dispatch(report(API, cashes)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CashReport)
);

