import moment from 'moment';
import numeral from 'numeral';

import TruncateString from 'utils/truncate-string.js';


export default (state = {
  notFound: null,
}, action) => {
  switch (action.type) {
    case 'ADMIN-CASH-RECEIVE-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'ADMIN-CASH-RECEIVE-SET':
    {
      const {
        rows,
      } = action;
      /* eslint-disable no-useless-rename */
      const {list: cashReports} = rows.reduce((acc, t) => {
        if (!acc.index.hasOwnProperty(t.id)) {
          acc.index[t.id] = acc.list.length;
          acc.list.push({
            id: t.id,
            reporter: t.reporter,
            reportDateStr: moment(t.reportDate * 1000).format('DD-MM'),
            receiver: t.receiver,
            receiveDateStr: t.receiveDate !== null ? moment(t.receiveDate * 1000).format('DD-MM') : null,
            open: t.receiveDate === null,
            jobs: [],
            total: 0,
          });
        }
        const b = acc.list[acc.index[t.id]];
        b.jobs.push({
          jobId: t.jobId,
          jobNumber: t.jobNumber,
          rego: t.rego,
          makeModel: TruncateString([t.make || '', t.model || ''].join(' ')),
          amountStr: numeral(t.amount / 100).format('$0,0.00'),
          payDateStr: moment(t.payDate * 1000).format('DD-MM'),
          payee: t.payee,
        });
        b.total += t.amount;
        return acc;
      }, {list: [], index: {}});
      cashReports.forEach(cr => {
        cr.totalStr = numeral(cr.total / 100).format('$0,0.00');
      });
      return {
        notFound: false,
        cashReports,
      };
    }
    case 'ADMIN-CASH-RECEIVE-TOGGLE':
    {
      const {index} = action;
      const cashReports = state.cashReports.reduce((acc, t, i) => {
        let newT = t;
        if (i === index) {
          newT = {
            ...t,
            open: !t.open,
          }
        }
        acc.push(newT);
        return acc;
      }, []);
      return {
        ...state,
        cashReports,
      };
    }
    default:
      return state;
  }
};
