import moment from 'moment';
import numeral from 'numeral';

import TruncateString from 'utils/truncate-string.js';


export default (state = {
  notFound: null,
}, action) => {
  switch (action.type) {
    case 'ADMIN-CASH-REPORT-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'ADMIN-CASH-REPORT-SET':
    {
      const {
        cashes: _cashes,
      } = action;
      /* eslint-disable no-useless-rename */
      const {total, cashes} = _cashes.reduce((acc, t) => {
        const selected = t.isOwn === true;
        if (selected) {
          acc.total += t.amount;
        }
        acc.cashes.push({
          ...t,
          selected,
          dateStr: moment(t.payDate * 1000).format('DD-MM'),
          amountStr: numeral(t.amount / 100).format('$0,0.00'),
          makeModel: TruncateString([t.make || '', t.model || ''].join(' ')),
        });
        return acc;
      }, {total: 0, cashes: []});
      return {
        notFound: false,
        cashes,
        total: numeral(total / 100).format('$0,0.00'),
      };
    }
    case 'ADMIN-CASH-REPORT-TOGGLE':
    {
      const {index, selected} = action;
      const {total, cashes} = state.cashes.reduce((acc, t, i) => {
        let newT = t;
        if (i === index) {
          newT = {
            ...t,
            selected
          }
        }
        if (newT.selected === true) {
          acc.total += t.amount;
        }
        acc.cashes.push(newT);
        return acc;
      }, {total: 0, cashes: []});
      return {
        ...state,
        cashes,
        total: numeral(total / 100).format('$0,0.00'),
      };
    }
    default:
      return state;
  }
};
