import {combineReducers} from 'redux';

import customer from './customer';
import claim from './claim';
import survey from './survey';

import debtorDashboard from './debtor/dashboard';
import debtorReport from './debtor/report';

import addMiscInvoice from './internal/add-misc-invoice';
import addTask from './internal/add-task';
import adminCreditorInvoices from './internal/admin-creditor-invoices';
import assessmentInbox from './internal/assessment-inbox';
import adminCashReport from './internal/admin-cash-report';
import adminCashReceive from './internal/admin-cash-receive';
import adminCreditorPayments from './internal/admin-creditor-payments';
import adminCredits from './internal/admin-credits';
import adminMiscInvoices from './internal/admin-misc-invoices';
import adminPayments from './internal/admin-payments';
import adminExcessPayments from './internal/admin-excess-payments';
import adminReportsInvoice from './internal/admin-reports-invoice';
import adminReportsLocation from './internal/admin-reports-location';
import adminReportsProfitLoss from './internal/admin-reports-profit-loss';
import adminReportsStormActivity from './internal/admin-reports-storm-activity';
import adminReportsStormCategory from './internal/admin-reports-storm-category';
import adminReportsTechPayments from './internal/admin-reports-tech-payments';
import adminReportsSurvey from './internal/admin-reports-survey';
import adminStatus from './internal/admin-status';
import adminTechnicianCommissions from './internal/admin-technician-commissions';
import authentication from './internal/authentication';
import bookingSchedule from './internal/booking-schedule';
import conventional from './internal/conventional';
import internalDashboard from './internal/dashboard';
import loading from './internal/loading';
import locationId from './internal/location-id';
import modal from './modal';
import jobDetails from './internal/job-details';
import jobExcessInvoice from './internal/job-excess-invoice';
import jobPrint from './internal/job-print';
import jobGallery from './internal/job-gallery';
import jobImport from './internal/job-import';
import jobInfosheet from './internal/job-infosheet';
import jobInvoice from './internal/job-invoice';
import jobLightTunnel from './internal/job-light-tunnel';
import jobLocations from './internal/job-locations';
import jobLogs from './internal/job-logs';
import jobNotes from './internal/job-notes';
import jobDocuments from './internal/job-documents';
import jobPayments from './internal/job-payments';
import jobPurchasing from './internal/job-purchasing';
import jobSmss from './internal/job-smss';
import jobSurvey from './internal/job-survey';
import jobQas from './internal/job-qas';
import menu from './internal/menu';
import params from './internal/params';
import production from './internal/production';
import settings from './internal/settings';
import tasks from './internal/tasks';
import outstandingInvoices from './internal/outstanding-invoices';
import technicianInvoicing from './internal/technician-invoicing';
import tracking from './internal/tracking';

import supplierGallery from './supplier/gallery';
import supplierList from './supplier/list';
import supplierOrder from './supplier/order';
import supplierQuote from './supplier/quote';
import supplierUserDetails from './supplier/user-details';


export default combineReducers({
  modal,
  loading,

  customer,
  claim,
  survey,

  debtorDashboard,
  debtorReport,

  addMiscInvoice,
  addTask,
  adminCashReport,
  adminCashReceive,
  adminCreditorInvoices,
  adminCreditorPayments,
  adminCredits,
  adminMiscInvoices,
  adminPayments,
  adminExcessPayments,
  adminReportsInvoice,
  adminReportsLocation,
  adminReportsProfitLoss,
  adminReportsStormActivity,
  adminReportsStormCategory,
  adminReportsTechPayments,
  adminReportsSurvey,
  adminStatus,
  adminTechnicianCommissions,
  authentication,
  assessmentInbox,
  bookingSchedule,
  conventional,
  internalDashboard,
  jobDetails,
  jobExcessInvoice,
  jobGallery,
  jobImport,
  jobInfosheet,
  jobInvoice,
  jobLightTunnel,
  jobLocations,
  jobLogs,
  jobNotes,
  jobPayments,
  jobPrint,
  jobPurchasing,
  jobSmss,
  jobSurvey,
  jobQas,
  jobDocuments,
  locationId,
  menu,
  params,
  production,
  settings,
  tasks,
  outstandingInvoices,
  technicianInvoicing,
  tracking,

  supplierGallery,
  supplierList,
  supplierOrder,
  supplierQuote,
  supplierUserDetails,
});
