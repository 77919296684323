import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  height: 100%;
  overflow: auto;
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Row = styled.tr`
  background: ${opts => opts.alert ? colors.redExtraLight : colors.white};
  & td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;


export default class CashReport extends React.Component {
  constructor(opts) {
    super(opts);
    this.onReport = this.onReport.bind(this);
  }
  componentDidMount() {
    const {onLoad} = this.props;
    onLoad();
  }
  onToggle(index, e) {
    this.props.onToggle(index, e.target.checked);
  }
  onReport() {
    const {cashes, onReport} = this.props;
    const rows = cashes.reduce((acc, t) => {
      if (t.selected === true) {
        acc.push(t.id);
      }
      return acc;
    }, []);
    onReport(rows)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while submitting cash report.');
        } else {
          toast.success('Cash report successfully submitted.');
        }
      })
      .catch(e => {
        toast.error('Error encountered while submitting cash report.');
      });
  }
  render() {
    console.log('cashreport:render');
    const {notFound} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load cash report.</div>
        </ErrorContainer>
      );
    }
    const {
      cashes,
      total,
    } = this.props;
    return (
      <Container className='flex-grow-1 p-3'>
        <h5 className='mb-0'>Cash Report</h5>
        <Card className='p-3 mt-3'>
          <table className='table mb-0 mt-3'>
            <thead>
              <tr>
                <th className='border-0 py-0'>Job #</th>
                <th className='border-0 py-0'>Rego</th>
                <th className='border-0 py-0'>Make/model</th>
                <th className='border-0 py-0'>Type</th>
                <th className='border-0 py-0'>Date</th>
                <th className='border-0 py-0'>Amount</th>
                <th className='border-0 py-0'>By</th>
                <th className='border-0 py-0' width={50} />
              </tr>
            </thead>
            <tbody>
              {cashes.length === 0 && (
                <div className='text-center text-secondary'>No cash remaining.</div>
              )}
              {cashes.map((t, i) => (
                <React.Fragment key={i}>
                  <tr>
                    <td className='p-1 border-0' />
                  </tr>
                  <Row>
                    <td>
                      <Link to={`/job/${t.jobId}`}>
                        <strong className='text-body'>{t.jobNumber}</strong>
                      </Link>
                    </td>
                    <td>{t.rego}</td>
                    <td className='text-uppercase'>{t.makeModel}</td>
                    <td className='text-uppercase'>{t.type === 'job-excess-invoice-credit' ? 'excess' : (t.type === 'job-invoice-credit' ? 'job' : t.type)}</td>
                    <td>{t.dateStr}</td>
                    <td>
                      {t.amountStr}
                    </td>
                    <td>
                      {t.receiver}
                    </td>
                    <td className='text-center'>
                      <input
                        type='checkbox'
                        checked={t.selected === true}
                        onChange={this.onToggle.bind(this, i)}
                      />
                    </td>
                  </Row>
                </React.Fragment>
              ))}
              <tr>
                <td
                  className='border-0 font-weight-bold pb-0 align-middle'
                  colSpan={5}
                  align='right'
                >Total</td>
                <td className='border-0 font-weight-bold pb-0 align-middle'>
                  {total}
                </td>
              </tr>
              <tr>
                <td className='border-0 pb-0' colSpan={7} />
                <td className='border-0 pb-0 align-middle'>
                  <button
                    className='btn btn-primary'
                    onClick={this.onReport}
                  >Report</button>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </Container>
    );
  }
};

